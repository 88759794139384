input.autocomplete-input:focus {
    border-radius: 8px 8px 0 0;
    border-bottom: none;
    outline: none;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
}

.results-pane {
    background-color: white;
    border-radius: 0 0 16px 16px;
    border: 1px solid black;
    width: 100%;
    box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
    margin-top: -1px;
    z-index: 22;
    display: block;

    &-option {
        padding: 12px 0 12px 12px;
        transition: all 0.2 ease-in-out;

        &:hover {
            background-color: #f1f1f1;
            cursor: pointer;
        }
    }
}

.result-pane-option:hover {
    background-color: #f1f1f1
}

.expanded {
    display: block;
}

.map-container div:first-child {
    position: relative !important;
    height: 450px !important;
}