@mixin btn-bg($color, $bg-color, $border-color) {
    color: $color;
    background-color: $bg-color;
    border-color: $border-color;
}

.btn {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 12px 24px;
    font-size: 16px;
    line-height: 1;
    border-radius: 6px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-transform: uppercase;
    cursor: pointer;

    @media only screen and (max-width: 769px) {
        font-size: 14px;
        padding: 12px 15px;
    }

    &Primary {
        @include btn-bg($white, $primary, $primary);

        &:hover {
            @include btn-bg($white, $primary-light, $primary-light);
        }

        &.space {
            margin-top: 56px;
        }
    }

    &Secondary {
        @include btn-bg($white, #6E6E6E, #6E6E6E);
    }

    &PrimaryOutlined {
        @include btn-bg($primary, $white, $primary);

        &:hover {
            @include btn-bg($primary-light, $white, $primary-light);
        }
    }

    &.block {
        width: 100%;
    }

    &.height {
        height: 58px;
    }
}