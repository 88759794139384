@import '../../common/scss/common.scss';

.pageBodyPanelWrap {
    .pageBodyLeftPanel {
        flex: 0 0 70%;

        @media only screen and (max-width: 769px) {
            flex: 0 0 100%;
        }

        .helpCard {
            &.active {
                background-color: #FDF9F6;
            }

            &:not(:first-child) {
                margin-top: 24px;
            }

            .cardHeader {
                display: flex;
            }
            
            .cardSection {
                .helpIssue {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                }
            }

            .cardBody {
                border-top: 1px solid #ededed;
                margin-top: 8px;
                padding-top: 8px;
            }

            .cardFooter {
                border-top: 1px solid #ededed;
                margin-top: 8px;
                padding-top: 8px;

                .helpDuration {
                    .duration {
                        &:not(:first-child) {
                            border-left: 1px solid #ededed;
                            padding-left: 8px;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }

    .pageBodyRightPanel {
        flex: 0 0 30%;
        position: sticky;
        top: 120px;
        height: calc(100vh - 185px);

        @media only screen and (max-width: 769px) {
            flex: 0 0 100%;
            margin-top: 40px;
        }

        .panelHeading {
            color: $primary;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 21px;

            &:not(:first-child) {
                margin-top: 36px;
            }
        }

        .actionRow {
            margin-top: 40px;

            @media only screen and (max-width: 769px) {
                padding-bottom: 80px;
            }
        }
    }
}