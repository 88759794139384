@mixin alert-bg($color, $bg-color, $border-color) {
    color: $color;
    background-color: $bg-color;
    border-color: $border-color;
}

.alert {
    position: fixed;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    padding: 8px 24px;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 6px;
    max-width: 500px;
    z-index: 102;
    text-align: center;

    @media only screen and (max-width: 1148px) {
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
    }

    svg {
        margin-right: 12px;
    }

    &.success {
        @include alert-bg(#27AE60, #E6F9EE, #E6F9EE)
    }

    &.error {
        @include alert-bg(#ae2727, #f9e6e6, #f9e6e6)
    }
}