// Color palette ------------------------------

$primary: #EF8658;
$secondary: #006CEB;

$primary-light: #ef6c32;
$secondary-light: #0065d8;

// Global Colors ------------------------------

$black: #000;
$white: #fff;