@import '../../../common/scss/common.scss';

.popupCardWrap {
    position: fixed;
    top: 50%;
    z-index: 100;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    max-width: 350px;

    .popupCard {
        border-radius: 12px;
        background: #fff;
        padding: 32px 16px;

        &Header {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
        }

        &Body {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            margin-top: 8px;
        }

        &Footer {
            margin-top: 24px;

            .btn {
                flex-grow: 1;
                justify-content: center;

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }
    }
}

.bgmask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 60;
    background-color: rgba(#000, 0.3);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}