@import '../../common/scss/common.scss';

.reportsWrapper {
    .tableResponsive {
        width: 100%;
        overflow: auto;

        .reports {
            &.table {
                width: 100%;

                thead>tr>td {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    text-align: left;
                    background-color: $primary;
                    padding: 12px;
                    color: #fff;

                    &:nth-child(1) {
                        width: unset;
                    }
                }

                tbody>tr>td {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    padding: 16px 12px;
                    text-align: left;
                    color: #000;
                    border-bottom: 1px solid #d4d4d4;
                }

                tfoot>tr>td {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    padding: 16px 12px;
                    text-align: left;
                    color: #000;
                    border-bottom: 1px solid #d4d4d4;
                    background-color: #EDEDED;
                    font-weight: 700;
                }

                tbody>tr:hover {
                    &:hover {
                        background-color: rgba(239, 134, 88, 0.1);
                        cursor: pointer;
                    }
                }

            }
        }
    }
}

.buttonOptionsWrapper {
    .buttonOptions {
        background-color: #fff;
        box-shadow: 0px 4px 10px 0px rgba(#000, 0.4);
        position: absolute;
        bottom: calc(100% + 2px);
        right: 0;
        padding: 8px;
        border-radius: 6px;
        width: 150px;

        .option {
            padding: 8px;
            cursor: pointer;

            &:hover {
                background-color: #FDF2EC;
            }
        }
    }
}