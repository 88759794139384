@import '../../common/scss/common.scss';

.pageBody {
    margin-left: 80px;
    padding: 20px;
    padding-top: 110px;
    position: relative;

    @media only screen and (max-width: 769px) {
        margin-left: 0;
        padding-bottom: 80px;
        padding-top: 90px;
    }

    .pageBodyLeftPanel {
        &.sticky {
            height: calc(100% - 250px);
            overflow: auto;
            position: sticky;
            top: 110px;
        }
    }

    .pageBodyRightPanel {
        padding: 0 20px;
        width: calc(100% - 260px);

        @media only screen and (max-width: 769px) {
            width: 100%;
            padding: 0;
        }

        &Header {
            @media only screen and (max-width: 769px) {
                flex-wrap: nowrap;
            }

            .pageBodyRightPanelHeading {
                font-size: 24px;
                line-height: 28px;
                font-weight: 600;
                color: #212121;
                align-self: flex-start;
            }
        }

        .productLlistWrap {
            margin-top: 20px;
            grid-gap: 20px;

            @media only screen and (max-width: 769px) {
                margin-top: 0;
                grid-gap: unset;
            }

            .cardWrap {
                flex: 0 0 calc(50% - 10px);

                @media only screen and (max-width: 1148px) {
                    flex: 0 0 100%;
                }

                @media only screen and (max-width: 769px) {
                    &:not(:first-child) {
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}

.consolidateStrip {
    background-color: #FCEBE3;
    padding: 16px;

    @media only screen and (max-width: 998px) {
        padding: 12px;
        // margin-left: -20px;
        // margin-right: -20px;

        .consolidatedDataIcon {
            svg {
                width: 40px;
            }
        }
    }

    .consolidateDataText {
        font-weight: 600;
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        small {
            font-size: 12px;

            &.weightNormal {
                font-weight: 400;
            }
        }
    }

    .consolidateData {
        font-size: 40px;
        color: #212121;
        font-weight: 600;
        margin-left: auto;
        font-family: 'Barlow Condensed', sans-serif !important;
    }
}

.chainNavigationWrapper {
    width: 260px;

    // @media only screen and (max-width: 769px) {
    //     display: none;
    // }

    .chainNavigation {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: calc(100vh - 130px);
        border: 1px solid #ededed;
        overflow: auto;

        .chainItem {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EDEDED;
            cursor: pointer;
            color: $primary;
            padding: 10px 18px;
            transition: all 0.2s ease-in-out;

            span {
                margin-left: 16px;
            }

            &:not(:first-child) {
                border-top: 0;
            }

            &:hover,
            &.active {
                color: $white;
                background-color: $primary;

                svg>g>path {
                    fill: #fff;
                }
            }
        }
    }
}