.disclaimerNotes {
    border-top: 4px solid #ededed;
    margin-top: 16px;
    padding-top: 6px;

    &Heading {
        font-weight: 600;
    }

    &Desc {
        margin-top: 4px;
        font-size: 16px;
        color: #6e6e6e;

        .boldText {
            font-weight: bold;
        }

        .iconWrapper {
            display: inline-flex; 
            align-items: center;
            margin-left: 20px;
        }

        svg {
            vertical-align: middle; 
        }
    }
}