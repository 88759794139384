@import '../../common/scss/common.scss';

.containerHome {
    .pageBody {
        .homeLeftWrapper {
            width: 60%;

            @media only screen and (max-width: 998px) {
                width: 100%;
            }

            .homeBar {
                background-color: #FDF2EC;
                padding: 16px 16px;
                
                @media only screen and (max-width: 768px) {
                    flex-wrap: nowrap;
                    overflow: auto;
                    margin-left: -20px;
                    margin-right: -20px;
                    

                    
                }
            }

            .centerDetailsWrap {
                .centerOwner {
                    font-size: 16px;
                    line-height: 20px;
                    color: #000;
                }

                .centerName {
                    font-size: 24px;
                    line-height: 28px;
                    font-weight: 600;
                    color: #000;
                    cursor: pointer;

                    @media only screen and (max-width: 998px) {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    svg {
                        transform: rotate(-90deg);
                    }
                }
            }

            .moneyBar {
                margin-top: 16px;
                // padding: 16px 24px;
                background-color: #FDF2EC;

                @media only screen and (max-width: 768px) {
                    flex-wrap: nowrap;
                    overflow: auto;
                    // margin-left: -20px;
                    // margin-right: -20px;

                    .moneyBarItem {
                        min-width: fit-content;

                        &:not(:first-child) {
                            margin-left: 45px;
                        }
                    }
                }

                &ItemHeading {
                    font-size: 12px;
                    line-height: 16px;
                    color: $primary;
                }

                .moneyBarValue {
                    margin-top: 4px;

                    .headingValue {
                        font-size: 36px;
                        line-height: 32px;
                        font-weight: 500;
                        color: $primary;
                        font-family: 'Barlow Condensed', sans-serif !important;
                    }

                    .headingValuePercent {
                        margin-left: 10px;
                        font-size: 12px;
                        font-weight: 600;
                        margin-top: 8px;
                    }
                }
            }

            .dashboardGrid {
                grid-gap: 22px;
                margin-top: 20px;

                @media only screen and (max-width: 1148px) {
                    grid-gap: 12px;
                }

                .gridItem {
                    border: 1px solid #d4d4d4;
                    border-radius: 16px;
                    padding: 32px 16px 20px;
                    flex: 0 0 calc(33.33% - 16px);
                    max-width: calc(33.33% - 16px);
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    text-align: center;

                    @media only screen and (max-width: 1148px) {
                        flex: 0 0 calc(33.33% - 8px);
                        max-width: calc(33.33% - 8px);
                        padding: 8px;
                    }

                    &Icon {
                        svg {
                            @media only screen and (max-width: 1148px) {
                                width: 36px;
                                height: 36px;
                            }
                        }
                    }

                    &Name {
                        font-size: 20px;
                        line-height: 24px;
                        color: #121212;
                        margin-top: 14px;

                        @media only screen and (max-width: 1148px) {
                            font-size: 10px;
                            line-height: 14px;
                            word-break: break-word;
                            margin-top: 8px;
                        }
                    }

                    &:hover {
                        background-color: #d4d4d4;
                    }
                }
            }
        }

        .homeRightWrapper {
            width: 40%;
            padding-left: 30px;

            @media only screen and (max-width: 998px) {
                width: 100%;
                padding-left: 0;
                margin-top: 30px;
            }

        }
    }
}

.tabs {
    width: 100%;
    flex-wrap: nowrap;
    align-items: end;

    .tabItem {
        flex-grow: 1;
        text-align: center;
        border-bottom: 2px solid #dedede;
        font-size: 18px;
        line-height: 22px;
        color: #6e6e6e;
        padding-bottom: 4px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        @media only screen and (max-width: 768px) {
            font-size: 16px;
            font-weight: 500;
        }

        &:hover,
        &.active {
            color: $primary;
            border-bottom: 2px solid $primary;
        }
    }
}

.graphFilterTabs {
    margin-top: 24px;
    margin-bottom: 24px;

    .graphTabs {
        .tabItem {
            font-size: 14px;
            color: #6e6e6e;
            padding: 10px;
            border-radius: 4px;
            border: 1px solid #d4d4d4;
            flex-grow: 1;
            text-align: center;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover,
            &.active {
                color: $white;
                background-color: #121212;
            }

            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }
}