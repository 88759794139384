@import '../../common/scss/common.scss';

.personalDetailsRow {
    margin-top: 24px;

    .personalIcon {
        height: 82px;
        width: 82px;
        background-color: #ededed;
        border-radius: 50%;
        margin-right: 16px;
        flex-shrink: 0;
        overflow: hidden;

        .iconImage {
            width: 100%;
            height: 100%;
        }
    }

    .personalNameWrap {
        .personalName {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: #121212;
        }

        .personalDesc {
            font-size: 16px;
            line-height: 20px;
            color: #6e6e6e;
            margin-top: 4px;

            @media only screen and (max-width: 768px) {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}

.otherDetailsRow {
    margin-top: 20px;

    .otherDetailsWrap {
        .otherDetailsHeading {
            font-size: 16px;
            line-height: 20px;
            color: $primary;
            font-weight: 600;
            margin-top: 24px;

            svg {
                cursor: pointer;
            }
        }

        .otherDetailsEdit {
            flex-direction: row;
            display: flex;
            gap: 16px;
            align-items: center;

            button {
                margin: 0;
            }

            svg {
                flex-shrink: 0;
            }
        }

        .otherDetail {
            margin-top: 12px;
            padding-bottom: 12px;

            &:not(:first-child) {
                border-bottom: 1px solid #d4d4d4;
            }

            &Label {
                font-size: 12px;
                line-height: 16px;
                color: #6e6e6e;
            }

            &Data {
                font-size: 16px;
                line-height: 20px;
                color: #121212;
                font-weight: 600;
                margin-top: 2px;
            }
        }
    }
}

.storeTime,
.profileLogo {
    display: flex;
    align-items: center;
    gap: 16px;

    .start,
    .close {
        max-width: 50%;
        min-width: 320px;
        cursor: pointer;
    }
}

.profileLogo {
    justify-content: space-between;
    label {
        cursor: pointer;
    }

    &Icon {
        width: 50px;
        stroke: $primary;
    }

    &Inner {
        &Name {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
                height: 24px;
                width: 24px;
                cursor: pointer;
                svg {
                    stroke: $primary;
                }
            }
        }
    }
}

.storeTime {
    position: relative;

    .timePopup {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        max-width: 400px;
        width: 50%;
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 24px;
        z-index: 1;
        &Header {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 16px;
        }

        &Body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;
            font-size: 3rem;
            font-weight: 500;
            text-align: center;

            .timePopupLabel {
                width: 10%;
            }

            .timePopupTime {
                width: 45%;

                input {
                    text-align: center;
                    max-width: 100%;
                    border: 2px solid #d4d4d4;

                    &:focus {
                        border: 2px solid $primary;
                    }
                }

                &Subtitle {
                    font-size: 12px;
                    line-height: 16px;
                    color: #6e6e6e;
                    margin-top: 4px;
                }
            }
        }

        &Footer {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
        }
    }
}

.passwordMatch {
    position: absolute;
    right: 10px;
    top: 58%;
    transform: translateY(-50%);
}
