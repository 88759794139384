.formGroup {
    margin-bottom: 1rem;

    &.formFloating {
        position: relative;

        .formControl {
            padding: 1rem 0.75rem;
            height: calc(3.5rem + 2px);

            &:focus {
                padding-top: 1.625rem;
                padding-bottom: 0.625rem;

                ~label {
                    opacity: .65;
                    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
                }
            }

            &:not(:placeholder-shown) {
                padding-top: 1.625rem;
                padding-bottom: 0.625rem;

                ~label {
                    opacity: .65;
                    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
                }
            }
        }

        >label {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding: 1rem 0.75rem;
            pointer-events: none;
            border: 1px solid transparent;
            transform-origin: 0 0;
            transition: opacity .1s ease-in-out, transform .1s ease-in-out;
        }
    }

    &.formInputText {
        .preInputGroup {
            width: 58px;
            max-height: 58px;
            height: 100%;
            position: absolute;
            border-radius: 6px;
            color: #121212;

            &.backColor {
                background-color: #ccc;
            }

            .preInputText {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            +.formControl {
                padding-left: 70px !important;

                +label {
                    padding-left: 70px !important;
                }

                &:not(:placeholder-shown) {
                    ~label {
                        opacity: .65;
                        transform: scale(.85) translateY(-0.5rem) translateX(0.75rem);
                    }
                }

                &:focus {
                    ~label {
                        padding-left: 70px !important;
                        transform: scale(.85) translateY(-0.5rem) translateX(0.75rem);
                    }
                }
            }

        }

        .postInputGroup {
            width: 40px;
            max-height: 40px;
            height: 100%;
            position: absolute;
            border-radius: 6px;
            color: #121212;
            right: 0;
            top: 18px;

            .postInputIcon {}
        }
    }

    .formSelect {
        display: block;
        width: 100%;
        padding: 16px 2.25rem 16px 12px;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
        color: #212529;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 1rem .75rem;
        border: 1px solid #D4D4D4;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' strokeWidth='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        padding-top: 1.625rem;
        padding-bottom: 0.625rem;

        +label {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding: 1rem 0.75rem;
            pointer-events: none;
            border: 1px solid transparent;
            transform-origin: 0 0;
            transition: opacity .1s ease-in-out, transform .1s ease-in-out;
        }

        ~label {
            opacity: .65;
            transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
        }

        &.reactSelect {
            background-image: none;
            padding: 0 2px;
        }
    }

    .formControl {
        display: block;
        width: 100%;
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #D4D4D4;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        &.formsm {
            padding: 8px;
        }
    }

    &.typeFile {
        border: 1px dashed #D4D4D4;
        padding: 16px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .beforeUpload {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .uploadFile {
            &.hide {
                display: none;
            }

            .retryUploadFile {
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                color: $secondary;
                cursor: pointer;
            }

            .cancelUpload {
                cursor: pointer;
            }
        }

        &:not(:first-child) {
            margin-top: 16px;
        }

        .inputHeading {
            font-size: 16px;
            color: #3A3A3A;

            span {
                color: $primary;
            }
        }

        .fileLabel {
            cursor: pointer;
        }
    }

    &.searchBar {
        position: relative;

        .formControl {
            padding: 8px 8px 8px 36px;
            width: 0;
            transition: all 0.2s ease-in-out;
            border: 0;
        }

        &.expand {
            .formControl {
                width: 300px;
                border: 1px solid #FAE9E2;
                background-color: #FAE9E2;

                @media only screen and (max-width: 768px) {
                    width: 300px;
                }

                @media only screen and (max-width: 475px) {
                    width: calc(100vw - 100px);
                }

                +.searchIcon {
                    svg {
                        width: 22px;
                        stroke: $primary;
                    }
                }
            }
        }

        .searchIcon {
            position: absolute;
            top: 6px;
            left: 8px;
            cursor: pointer;
        }
    }

    .inputSuggestionsWrap {
        .inputSuggestion {
            margin-top: 4px;

            .suggestion {
                font-size: 14px;
                line-height: 14px;
                color: $primary;
                cursor: pointer;

                &:not(:first-child) {
                    border-left: 1px solid #d4d4d4;
                    margin-left: 8px;
                    padding-left: 8px;
                }
            }
        }
    }
}

.formInline {
    .amountPercentage {
        margin-left: 16px;

        .currencyType {}

        .currencyValue {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: #3A3A3A;
        }
    }

    &.halfWidth {
        .formGroup {
            width: calc(50% - 16px);

            &:nth-of-type(2n) {
                margin-left: 16px;
            }
        }
    }
}

.formHeading {
    color: $primary;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 40px;
}

.invalidFeedback {
    // display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .75em;
    color: #dc3545;
}

.approvedFeedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .75em;
    color: #27AE60;
}