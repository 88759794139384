.mobileHeaderContainer {
    background-color: #fff;
    padding: 10px 16px;
    border-bottom: 1px solid #EDEDED;
    z-index: 100;
    border: none;

    .mobileHeader {
        height: 46px;

        .backButton {
            z-index: 100;
        }

        .headerRight {
            .notification {
                z-index: 100;
            }
        }
    }

    &.fixed {
        position: fixed;
        width: 100%;
        top: 0;
        min-height: 62px;
        height: auto;
        top: 0;
        left: 0;
        // box-shadow: 0 0 10px #dedede;
    }


    .productHeaderName {
        @media only screen and (max-width: 769px) {
            position: absolute;
            text-align: center;
            width: 100%;
            left: 0;
        }

        @media only screen and (max-width: 475px) {
            padding-right: 25px;
        }
    }
}