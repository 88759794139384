.orderBook {
    width: 100%;

    thead,
    tfoot {
        tr > td {
            background-color: #ededed;
            padding: 12px;

            &.alignright {
                text-align: right;
            }

            &.strong {
                font-weight: 600;
            }
        }
    }

    tbody {
        tr > td {
            padding: 12px;
            border-bottom: 1px solid #d4d4d4;
            color: #ef8658;

            &.alignright {
                text-align: right;
            }

            &.strong {
                font-weight: 600;
            }
        }
    }
}

.viewOrderBook {
    .viewProductHeader {
        .contactDetailsRowLogo {
            width: 40px;
            height: 40px;
        }

        .moreDetailsRow {
            margin-top: 0;
            width: calc(100% - 100px);
            flex-grow: 1;
        }

        .headerRowLeft {
            color: #6e6e6e;
        }

        .productSupplierAddress {
            margin-top: 16px;
        }
    }
}
