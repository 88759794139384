@import '../../../common/scss/common.scss';

.card {
    // width: 48%;  // TODO: test with all the cards
    border: 1px solid #ededed;
    padding: 16px 24px;
    border-radius: 12px;
    cursor: pointer;
    position: relative;

    &.spaceTop {
        padding-top: 28px;
    }

    &Crown {
        color: $white;
        padding: 4px 10px;
        border-radius: 4px;
        font-weight: 600;
        position: absolute;
        top: -18px;
        background-color: #6e6e6e;

        &.primary {
            background-color: $primary;
        }
    }

    .addressDetails {
        .addressType {
            font-size: 14px;
            line-height: 18px;
            color: #6e6e6e;
            margin-bottom: 8px;
        }

        .address {
            svg {
                font-size: 8px;
                width: 18px;
                flex-shrink: 0;
                margin-right: 8px;
                position: sticky;
                top: 0;
            }

            font-size: 16px;
            line-height: 22px;
            max-height: 48px;
            overflow: auto;
        }
    }

    &Header {
        .productName {
            margin-top: 4px;
        }

        &.border {
            border-bottom: 1px solid #ededed;
            padding-bottom: 8px;
            margin-bottom: 8px;
        }

        .cardIcon {
            width: 60px;
            height: 60px;
            background-color: #ededed;
            border-radius: 50%;
            margin-right: 12px;

            .userInitials {
                font-size: 24px;
                line-height: 28px;
                font-weight: 600;
                color: #6e6e6e;
            }
        }

        .cardHeaderDetails {
            .headerSup {
                font-size: 14px;
                line-height: 18px;
                color: #6e6e6e;
            }

            .headerMain {
                font-size: 20px;
                line-height: 28px;
                font-weight: 600;
                color: #212121;
            }

            .exchangeItem {
                font-size: 22px;
                line-height: 28px;
                font-weight: 600;
                color: rgba(#6e6e6e, 0.3);
            }
        }

        .cardHeaderRight {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            color: $primary;
            margin-left: auto;

            &.alignSelf {
                align-self: flex-end;
            }

            &.nowrap {
                white-space: nowrap;
            }

            .cardActionsWrapper {
                right: 24px;
                z-index: 20;
                min-width: 145px;
                position: absolute;
                border-radius: 6px;
                background-color: #fff;
                box-shadow: 0 0 10px #ccc;
                max-height: 0;
                transition: all 0.2s cubic-bezier(0.88, 0.21, 0.06, 0.06);
                overflow: hidden;
                padding: 0 8px;

                &.show {
                    max-height: 200px;
                    padding: 8px;
                }

                .actionItem {
                    font-size: 16px;
                    font-weight: 400;
                    color: #121212;
                    line-height: 24px;
                    padding: 8px 12px;

                    &.active,
                    &:hover {
                        background-color: #fdead9;
                    }
                }
            }
        }
    }

    .productName {
        .item {
            font-size: 16px;
            line-height: 24px;
            color: #6e6e6e;

            &.strong {
                font-weight: 600;
                color: #121212;
            }

            &:not(:first-child) {
                border-left: 1px solid #ededed;
                padding-left: 8px;
                margin-left: 8px;
            }
        }
    }

    &Body {
        &.border {
            border-bottom: 1px solid #ededed;
            padding-bottom: 8px;
            margin-bottom: 8px;
        }

        &.borderBig {
            border-bottom: 1px solid #ededed;
            padding-bottom: 16px;
            margin-bottom: 16px;
        }

        .documentsName {
            .item {
                &:not(:first-child) {
                    border-left: 1px solid #ededed;
                    padding-left: 8px;
                    margin-left: 8px;
                }
            }
        }

        .productDesc {
            font-size: 18px;
            line-height: 22px;
            margin-top: 8px;
            color: #6e6e6e;

            &.strong {
                font-weight: 600;
                color: #121212;
            }
        }

        .orderStatus {
            font-size: 18px;
            line-height: 22px;
            margin-top: 8px;
        }

        .userContactDetails {
            .userContact {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                color: #121212;
                display: flex;
                align-items: center;
                margin-top: 8px;

                svg {
                    margin-right: 8px;
                }
            }
        }

        .actionButtons {
            display: flex;

            .btn {
                flex-grow: 1;
            }
        }
    }

    &Footer {
        .itemBack {
            @media only screen and (max-width: 769px) {
                flex-direction: column;

                // .loss {
                //     flex: 0 0 50%;
                //     width: 50%;
                // }
            }

            .item {
                color: $primary;
                font-size: 18px;
                line-height: 22px;
                font-weight: 600;

                small {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                }

                &:not(:first-child) {
                    border-left: 1px solid #d4d4d4;
                    padding-left: 8px;

                    @media only screen and (max-width: 769px) {
                        border-left: 0;
                        padding-left: 0;
                        margin-top: 8px;
                    }
                }

                &.no-border {
                    border-left: 0 !important;
                    padding-left: 0 !important;
                }
            }
        }
    }
}
