@import '../../../common/scss/common.scss';

.sideDrawer {
    width: 620px;
    height: calc(100vh - 90px);
    background-color: #fff;
    position: fixed;
    right: 0;
    top: 90px;
    overflow: auto;
    box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.04);
    border-left: 1px solid #EDEDED;
    transition: all 0.25s ease-in-out;
    z-index: 200;

    &Header {
        @media only screen and (min-width: 769px) {
            padding: 24px;
            padding-bottom: 12px;

            &.fixed {
                position: sticky;
                top: 0;
                z-index: 51;
                background-color: #fff;
            }
        }
    }

    @media only screen and (max-width: 769px) {
        width: 100%;
        top: 58px;
        height: calc(100vh - 58px);
        border: 0;
        padding: 16px;

        .sideDrawerHeader {
            flex-wrap: nowrap;
        }
    }

    &Heading {
        font-size: 20px;
        font-weight: 600;

        @media only screen and (max-width: 769px) {
            font-size: 18px;
        }
    }

    &.hide {
        right: -100%;
    }

    .generateQR {
        cursor: pointer;
        text-transform: uppercase;
        margin: 16px 0;
        text-align: right;
    }

    &Body {
        overflow: auto;
        position: relative;

        @media only screen and (min-width: 769px) {
            padding: 12px 24px;
            // height: calc(100vh - 66px);
        }

        @media only screen and (max-width: 769px) {
            height: calc(100vh - 66px);
        }
    }

    &Footer {
        background: #FFF;
        padding: 16px;
        position: absolute;
        bottom: 30px;
        width: 100%;
        left: 0;
        // box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);

        @media only screen and (min-width: 769px) {
            padding: 12px 24px;
        }
    }

    .sideDrawerFooter {
        .btn:not(:first-child) {
            margin-left: 16px;

        }
    }
}