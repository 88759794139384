@import '../../common/scss/common.scss';

.ExchangeReturn {
}

.exchangeReturnDetails {
    margin-bottom: 32px;
    margin-bottom: 28px;

    .exchangeReturnName,
    .exchangeReturnContact {
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        color: #121212;

        @media only screen and (max-width: 769px) {
            font-size: 15px;
        }
    }

    .exchangeReturnContact {
        border-left: 3px solid;
        padding-left: 10px;
        margin-left: 10px;
    }

    .exchangeReturnLocation {
        font-size: 18px;
        color: $primary;
        margin-top: 8px;
        cursor: pointer;

        @media only screen and (max-width: 769px) {
            font-size: 14px;
        }

        svg {
            stroke: $primary;
            width: 20px;
            margin-right: 8px;
        }
    }
}

.table {
    &.exchange {
        thead > tr > td {
            background-color: #dedede;
            text-align: left;
            padding: 12px 16px;
            color: unset;

            &:nth-child(1) {
                width: 5%;
                color: #212121;
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }

        tbody > tr > td {
            padding: 16px 8px;
            color: $primary;
            border-bottom: 1px solid #d4d4dd;

            &:last-child {
                text-align: right;
            }
        }
    }

    &.viewExchange {
        thead > tr > td {
            background-color: #dedede;
            text-align: right;
            padding: 12px 16px;
            color: unset;

            &:nth-child(1) {
                width: 40%;
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }

        tbody > tr > td {
            padding: 16px;
            color: $primary;
            line-height: 20px;
            border-bottom: 1px solid #d4d4dd;
            text-align: right;

            &:first-child {
                text-align: left;
            }

            .exchangeQty,
            .exchangeVal {
                color: #6e6e6e;
                margin-top: 8px;
            }
        }
    }

    &.productPrice {
        tbody > tr > td {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            padding: 8px 0;
            text-align: right;

            &:first-child {
                text-align: left;
            }

            .border {
                border: 4px solid #ededed;
                margin: 12px 0;
            }

            &.strong {
                font-weight: 600;
            }
        }
    }
}

.exchangeValueRow {
    margin: 16px 0;
    padding: 16px;
    background-color: #dedede;
}
