@import '../../common/scss/common.scss';

.table {
    width: 100%;
    // &.setUserPermissions {
        thead>tr>td {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            padding: 8px 0;
            text-align: center;
    
            &:nth-child(1) {
                color: $primary;
                width: 80%;
                text-align: left;
            }
        }
    
        tbody>tr>td {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            padding: 8px 0;
            text-align: center;
    
            @media only screen and (max-width: 475px) {
                font-size: 14px;
                line-height: 18px;
            }
    
            &:nth-child(1) {
                text-align: left;
            }
    
            .border {
                border: 4px solid #ededed;
                margin: 12px 0;
            }
    
            &.strong {
                font-weight: 600;
            }
        }
    // }
   
}