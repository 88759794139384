.profileMenuWrapper {
    margin-top: 24px;

    .profileMenuList {
        .menuItem {
            display: flex;
            align-items: center;
            padding: 20px 24px;
            border-top: 1px solid #bababa;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            cursor: pointer;

            &:last-child {
                border-bottom: 1px solid #bababa;
            }

            svg {
                max-width: 24px;
                margin-right: 8px;
            }
        }
    }

    .logOut {
        font-size: 16px;
        line-height: 20px;
        color: #006CEB;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 70px;
        text-align: center;
        cursor: pointer;
    }
}