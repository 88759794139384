.informationCondition {

    .infoPara,
    .infoPage>.infoItem {
        font-size: 16px;
        line-height: 24px;
        color: #121212;
        font-weight: 400;

        @media only screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .infoPara {
        margin-top: 24px;

        em {
            font-weight: 600;
        }
    }

    .infoItem {
        margin-top: 24px;
        list-style-type: disc;
        padding-left: 12px;
        margin-left: 24px;

        .infoItem {
            margin-top: 24px;
        }
    }
}

.mainInfoList {
    counter-reset: item;
    list-style-type: decimal;
    padding: 20px;
    position: relative;

    .mainInfoListItem {
        color: #121212;
        display: block;
        font-size: 16px;
        line-height: 20px;
        padding-left: 32px;

        &.firstLevel {
            padding-left: 10px;
        }

        span {
            font-weight: 600;
        }

        &:before {
            content: counters(item, ".") " ";
            counter-increment: item;
            font-weight: 600;
            position: absolute;
            left: 0;
        }

        &:not(:first-child) {
            margin-top: 24px;
        }

        @media only screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

}