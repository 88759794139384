@import '../../common/scss/common.scss';

.orderBook {
    width: 100%;

    thead,
    tfoot {
        tr>td {
            background-color: #EDEDED;
            padding: 12px;

            &.alignright {
                text-align: right;
            }

            &.strong {
                font-weight: 600;
            }
        }
    }

    tbody {
        tr>td {
            padding: 12px;
            border-bottom: 1px solid #D4D4D4;
            color: #EF8658;

            &.alignright {
                text-align: right;
            }

            &.strong {
                font-weight: 600;
            }
        }
    }
}

.cardHeaderRight {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: $primary;
    margin-left: auto;
    position: relative;

    &.alignSelf {
        align-self: flex-end;
    }

    &.nowrap {
        white-space: nowrap;
    }

    .cardActionsWrapper {
        right: 0;
        z-index: 20;
        min-width: 145px;
        position: absolute;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0 0 10px #ccc;
        max-height: 0;
        transition: all 0.2s cubic-bezier(.88, .21, .06, .06);
        overflow: hidden;
        padding: 0 8px;

        &.show {
            max-height: 200px;
            padding: 8px;
        }

        .actionItem {
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 24px;
            padding: 8px 12px;
            cursor: pointer;

            &.active,
            &:hover {
                background-color: #FDEAD9;
            }
        }
    }
}

.discountCardWrapper {
    .cardWrapperHeading {
        font-size: 14px;
        line-height: 21px;
        color: #6E6E6E;
    }

    .discountCard {
        &.active {
            background-color: #D4D4D4;
            padding: 20px;
            border-radius: 12px;

            .discountDuration {
                font-size: 16px;
                line-height: 24px;
                color: #3A3A3A;
                font-weight: 600;
            }

            .discountName {
                font-size: 24px;
                line-height: 36px;
                color: #3A3A3A;
                font-weight: 600;
            }

            .discountProducts {
                font-size: 14px;
                line-height: 21px;
                color: #3A3A3A;
            }

            .discount {
                font-size: 32px;
                line-height: 48px;
                color: #3A3A3A;
                font-weight: 600;
            }
        }

        &.previous {
            border: 1px solid #D4D4D4;
            padding: 20px;
            border-radius: 12px;
            box-shadow: 0 0 20px 0 rgba(#000, 0.1);
            flex-basis: calc(50% - 8px);

            &:nth-child(2n) {
                margin-left: 16px;
            }

            &:nth-child(n + 3) {
                margin-top: 16px;
            }

            .discountDuration {
                font-size: 12px;
                line-height: 18px;
                color: #212121;
            }

            .discountName {
                font-size: 20px;
                line-height: 30px;
                color: #212121;
                font-weight: 600;
            }

            .discountProducts {
                font-size: 14px;
                line-height: 21px;
                color: #3A3A3A;
            }

            .discount {
                font-size: 20px;
                line-height: 30px;
                color: #EF8658;
                font-weight: 600;
            }
        }

        &.edit {
            padding: 8px;
            border-radius: 12px;

            .discountDuration {
                font-size: 16px;
                line-height: 24px;
                color: #3A3A3A;
                font-weight: 600;
            }

            .discountName {
                font-size: 24px;
                line-height: 36px;
                color: #3A3A3A;
                font-weight: 600;
            }

            .discountProducts {
                font-size: 14px;
                line-height: 21px;
                color: #3A3A3A;
            }

            .discount {
                font-size: 24px;
                line-height: 36px;
                color: #3A3A3A;
                font-weight: 600;
            }
        }

    }
}