@import '../../../common/scss/common.scss';

.header {
    background-color: #fff;
    padding: 10px 16px;
    border-bottom: 1px solid #ededed;
    z-index: 100;

    &.fixed {
        position: fixed;
        width: 100%;
        height: 90px;
        top: 0;
    }

    .headerLeft {
        .logoWrapper {
            .logo {
                cursor: pointer;
                svg {
                    // @media only screen and (max-width: 769px) {
                    //     width: 54px;
                    // }
                }
            }

            .logoName {
                margin-left: 16px;

                span {
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #212121;
                }

                small {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #6e6e6e;
                }
            }
        }
    }

    .headerRight {
        .notification {
            z-index: 100;
        }
        .activeToggle {
            border-radius: 999px;
            padding: 2px;
            background-color: $primary;

            height: 20px;
            width: 36px;
            cursor: pointer;

            display: flex;
            transition: 0.3s all ease-in;

            &.active {
                justify-content: flex-end;
            }

            &.inactive {
                justify-content: flex-start;
                background-color: #ccc;
            }

            .activeToggleIcon {
                border-radius: 50%;
                background-color: $white;
                height: 16px;
                width: 16px;
                transition: 0.3s all ease-in;
            }
        }

        .loggedInUser {
            position: relative;

            &Name {
                cursor: pointer;
            }

            .loggedInUserOptions {
                position: absolute;
                top: calc(100% + 8px);
                right: 0;
                background-color: $white;
                box-shadow: 0 0 5px #ccc;
                border-radius: 4px;
                overflow: hidden;

                .option {
                    padding: 8px 24px;
                    cursor: pointer;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
        }
    }
}
