@import '../../common/scss/common.scss';

.itemBased {
    .itemName {
        font-size: 20px;
        line-height: 24px;
        color: $primary;
        margin-top: 24px;
        font-weight: 600;
    }

    .itemDetails {
        margin-top: 8px;

        .item {
            font-size: 18px;
            line-height: 22px;
            color: #121212;

            &:not(:first-child) {
                border-left: 1px solid #D4D4D4;
                margin-left: 8px;
                padding-left: 8px;
            }
        }
    }
}