.DamageLoss {
    .formSection:not(:first-child) {
        margin-top: 8px;
    }

    .consolidateStrip {
        padding: 8px;

        .consolidatedDataIcon {
            svg {
                width: 40px;
                height: 40px;
            }
        }
    }
}