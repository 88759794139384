@import '../../common/scss/common.scss';

.loginPage {
    background-color: $white;
    min-height: 100vh;
}

.loginLeft {
    flex: 0 0 50%;
    min-height: inherit;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212121;
    height: 100vh;
    overflow: hidden;

    &Control {
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        align-items: center;

        .appName {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            color: $white;
            margin-top: 20px;
        }

        .appDesc {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: $white;
            text-align: center;
            margin-top: 2px;
        }
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.loginRight {
    flex: 0 0 50%;
    min-height: inherit;
    position: relative;
    display: flex;

    @media only screen and (max-width: 768px) {
        flex: 0 0 100%;
    }

    &Control {
        padding: 40px 120px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        overflow: auto;
        height: 100vh;

        &.verticalCenter {
            justify-content: center;
        }

        @media only screen and (max-width: 1148px) {
            padding: 40px 36px;
        }

        @media only screen and (max-width: 768px) {
            padding: 40px 16px;
        }

        .welcomeHeading {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            color: #212121;
            // text-transform: uppercase;

            @media only screen and (max-width: 768px) {
                font-size: 20px;
                line-height: 28px;
            }

            span {
                color: $primary;
            }
        }

        .welcomeSubHeading {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            margin-bottom: 16px;
        }

        .welcomeDesc {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #6E6E6E;
            margin-top: 4px;

            span {
                color: $primary;
                font-weight: 600;
            }

            @media only screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        form {
            margin-top: 40px;

            .formInline {
                margin-bottom: 1rem;

                .maxWidth {
                    max-width: 120px;
                }
            }

            .forgotPassword {
                text-align: right;
                font-size: 14px;
                line-height: 18px;
                font-weight: 600;
                color: $primary;
                text-transform: uppercase;
                cursor: pointer;
                margin-top: 10px;

                &:hover {
                    color: $primary-light;
                }
            }
        }
    }

    .createAccountWrap {
        margin-top: 48px;
        text-align: center;

        .createAccountText {
            font-size: 16px;
            color: #999;
        }

        .createAccount {
            font-size: 16px;
            line-height: 20px;
            color: $primary;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            margin-top: 4px;

            &:hover {
                color: $primary-light;
            }
        }
    }
}