.otp-verification-container {
    width: 100%;
}

.otpPage {
    .loginRight {
        &Control {
            justify-content: center;

            .formInline {
                justify-content: center;
            }
        }
    }

    .welcomeHeading {
        text-align: center;
    }

    .welcomeDesc {
        text-align: center;
        margin-top: 64px;
        line-height: 26px;

        span {
            cursor: pointer;
        }
    }

    .otpInput {
        .formControl {
            text-align: center;
            max-width: 60px;
            border: 0;
            border-radius: 0;
            border-bottom: 2px solid #ccc;
            outline: none;
        }
    }

    .otpInfo {
        text-align: center;
    }

    .invalidFeedback,
    .approvedFeedback {
        text-align: center;
        font-size: 1em;
    }
}
