[type="file"] {
    position: absolute;
    z-index: -1;
    width: 0;
}

.progress {
    width: 280px;
    padding: 0;
    margin-top: 12px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);

    @media only screen and (max-width: 769px) {
        width: 50vw;
    }

    &-bar {
        height: 12px;
        border-radius: 30px;
        background-image:
            linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
        transition: 0.4s linear;
        transition-property: width, background-color;

        @media only screen and (max-width: 769px) {
            height: 8px;
        }
    }
}

.progress-moved {
    .progress-bar {
        width: 100%;
        background-color: #EF8658;
        
    }

    .animation {
        animation: progressAnimation 1.6s;
    }
}

@keyframes progressAnimation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
        background-color: #EF8658;
    }
}