@import '../../common/scss/common.scss';

.accounting {
    .chooseTimeline {
        .formInline {
            .formGroup {
                min-width: 160px;

                @media only screen and (max-width: 769px) {
                    min-width: 130px;
                }
            }
        }
    }

    .accountingBodyWrapper {
        margin-top: 40px;

        .accountingTabsWrapper {
            margin-top: 20px;

            .accountingHalf {
                width: calc(50% - 12px);
                // max-height: calc(100vh - 275px);
                // overflow: auto;

                &:last-child {
                    margin-left: 24px;
                }

                @media only screen and (max-width: 998px) {
                    width: 100%;
                    max-height: unset;
                }

                .tableHeading {
                    font-size: 20px;
                    line-height: 30px;
                    color: $primary;
                    font-weight: 600;
                    margin-bottom: 8px;
                }
            }

            .accountingRight {
                width: calc(45% - 30px);
                max-height: calc(100vh - 275px);
                overflow: auto;
                margin-left: 30px;

                @media only screen and (max-width: 998px) {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 24px;
                    max-height: unset;
                }

                &FormHeading {
                    color: $primary;
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 24px;
                }
            }
        }
    }
}

.addAccountingBodyWrapper {
    margin-top: 36px;

    .voucherWidth {
        max-width: 320px;
    }
}

.table {
    &.voucherDetails {
        tbody>tr>td {
            text-align: left;
        }
    }

    &.accounting {
        .formGroup {
            margin-bottom: 0 !important;

            .formControl {
                &.form-sm {
                    padding: 8px;
                    font-size: 14px;
                }
            }
        }

        thead>tr>td {
            font-size: 16px;
            line-height: 22px;
            color: #212121;
            background-color: #EDEDED;
            text-align: left;
            padding: 12px;

            &:nth-child(1) {
                width: 12%;
                padding-left: 8px;
            }

            &.width {
                width: 4%;
            }

            &.textRight {
                text-align: right;
            }

            &.textBold {
                font-weight: 600;
            }
        }

        tbody>tr>td {
            color: $primary;
            text-align: left;
            padding: 12px 8px;
            border-bottom: 1px solid #D4D4D4;
            color: #212121;

            &:nth-child(1) {
                padding-left: 8px;
            }

            &:last-child {
                text-align: right;
            }

            &.nowrap {
                white-space: nowrap;
                padding-left: 16px;
            }

            &.border {
                border-bottom: 4px solid #ededed;
            }

            &.textRight {
                text-align: right;
            }

            &.textBold {
                font-weight: 600;
            }

            &.primary {
                background-color: #FAE9E2;
                color: $primary;
            }

            &.textPrimary {
                color: $primary;
            }
        }

        tfoot>tr>td {
            background-color: #EDEDED;
            padding: 12px;

            &.textRight {
                text-align: right;
            }

            &.textBold {
                font-weight: 600;
            }

        }
    }

    &.addAccounting {

        .formGroup {
            &.formFloating {
                margin-bottom: 0;

                >label {
                    padding: 1.1rem 0.75rem;
                }
            }
        }

        thead>tr>td,
        tbody>tr>td {
            vertical-align: top;

            &:last-child {
                text-align: unset;
            }
        }

        tbody>tr>td {
            padding: 16px 12px;

            &:first-child,
            &:last-child {
                vertical-align: middle;
            }
        }
    }
}

.addedAccountingDetails {
    .pharmacyName {
        font-size: 24px;
        line-height: 36px;
        color: #121212;
        font-weight: 600;
        text-align: center;
        margin-bottom: 12px;
    }

    .contactDetails {
        font-size: 16px;
        line-height: 24px;
        color: #121212;
        text-align: center;
        margin-bottom: 24px;
    }

    .table {
        margin-bottom: 16px;
    }
}

.accountingMainScreen {
    margin-top: 36px;

    .accountingForm {
        .accountingHalf {
            width: calc(50% - 12px);

            &:last-child {
                margin-left: 24px;
            }

            .accountingFormSection {
                &Bar {
                    background-color: #FAE9E2;
                    padding: 8px 16px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;

                    &Heading,
                    &Conclude {
                        font-size: 16px;
                        line-height: 24px;
                        color: #EF8658;
                        font-weight: 600;
                    }

                    &.insideHead {
                        background-color: transparent;
                        padding: unset;
                        padding-top: 8px;
                        padding-bottom: 8px;

                        .accountingFormSectionBarHeading {
                            color: #6E6E6E;
                        }
                    }
                }
            }
        }
    }
}

.addedItemListWrap {
    .addedItemList {
        border-top: 1px solid #d4d4d4;
        padding-top: 24px;

        .addedItem {
            .addedItemName {
                font-size: 16px;
                line-height: 20px;
                color: $primary;
                font-weight: 500;
            }

            .addedItemAction {
                svg {
                    cursor: pointer;

                    &:not(:first-child) {
                        margin-left: 16px;
                    }
                }
            }
        }
    }
}

.textPrimary {
    color: #EF8658;
    font-size: 16px;
}

.previousBarWrapper {
    margin-bottom: 24px;

    .chevron {
        transition: all 0.2s ease-in-out;

        &.move {
            width: 0;
            padding: 0;
        }
    }

    .previousBar {
        background-color: #FDF2EC;
        padding: 8px 16px;

        .presentData {
            text-decoration: underline;
            cursor: pointer;
            padding: 9px;
        }

        .btnPrimary {
            transition: all 0.2s ease-in-out;

            &.hide {
                transform: scale(0);
            }
        }
    }
}