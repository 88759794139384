@mixin text($font, $height, $weight, $color) {
    font-size: $font;
    line-height: $height;
    font-weight: $weight;
    color: $color;
}

.notificationWrapper {

    width: 40%;

    .notificationRow {
        padding: 16px;
        margin-left: -20px;
        margin-right: -20px;
        width: 100%;

        &.unread {
            background-color: #FDF2EC;
        }

        &Head {
            @include text(16px, 20px, 600, #000);
        }

        &Desc {
            @include text(12px, 16px, 400, #666);
            margin-top: 4px
        }

        &Foot {
            @include text(12px, 16px, 400, #999);
            margin-top: 12px;
        }
    }
}