.sideMenu {
    margin-top: 90px;
    background-color: #fff;
    border-right: 1px solid #EDEDED;
    max-width: 80px;

    @media only screen and (max-width: 768px) {
        // display: none;
        margin-top: 0;
        border-right: 0;
        z-index: 100;
        max-width: 0;
    }

    &.fixed {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;

        @media only screen and (max-width: 768px) {
            top: calc(100% - 57px);
        }
    }

    .navbarMenu {
        list-style-type: none;
        padding: 0;
        margin: 0;

        @media only screen and (max-width: 768px) {
            display: flex;
            align-items: flex-end;
            // height: 100%;
            width: 100vw;
            background: #FFF;
            box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.05);
        }

        .navbarIcon {
            padding: 30px 26px;
            cursor: pointer;

            @media only screen and (max-width: 768px) {
                background-color: #fff;
                padding: 16px 26px;
                flex-basis: 33.33%;
                text-align: center;
                border-top: 1px solid #EDEDED;
                line-height: 0;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            &:hover,
            &.active {
                background-color: #EDEDED;
            }
        }
    }
}