@import '../../common/scss/common.scss';

.WizardStepsWrap {
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 16px;
    padding: 0;
    list-style-type: none;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 769px) {
        margin-top: 14px;
    }

    .wizardStep {
        position: relative;
        width: 100%;

        &:last-child {
            width: 20px;
        }

        .circle {
            width: 16px;
            height: 16px;
            border: 2px solid #D4D4D4;
            border-radius: 50%;
            z-index: 50;
            position: relative;
            top: -7px;
            text-align: center;
            background-color: #D4D4D4;
            font-weight: 700;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #D4D4D4;

            &:last-child {
                right: 0;
            }
        }

        &:after {
            content: "";
            background-color: #D4D4D4;
            left: 20px;
            top: 0;
            height: 2px;
            position: absolute;
            width: calc(100% - 24px);
        }

        &:last-child {
            &:after {
                content: "";
                background-color: transparent;
            }
        }

        &.completed {
            color: $primary;
            font-weight: 700;

            &:after {
                content: "";
                background-color: $primary;
                left: 20px;
                top: 0;
                height: 2px;
                position: absolute;
                width: calc(100% - 24px);
            }

            .circle {
                color: $white;
                border: 2px solid $primary;
                background-color: $primary;
            }
        }

        &.inprogress {
            color: $primary;
            font-weight: 700;

            &:after {
                content: "";
                background-color: #D4D4D4;
                left: 20px;
                top: 0;
                height: 2px;
                position: absolute;
                width: calc(100% - 24px);
            }

            .circle {
                color: $primary;
                border: 2px solid $primary;
                background-color: $white;
            }
        }
    }
}

.wizardFieldset {
    position: relative;
    min-height: calc(100vh - 260px);

    @media only screen and (max-width: 769px) {
        height: calc(100vh - 250px);
        overflow: auto;
    }
}

.dualInlineGroup {
    flex-wrap: nowrap;

    .formGroup {
        &:not(:last-child) {
            margin-right: 16px;
        }
    }

    @media only screen and (max-width: 475px) {
        flex-wrap: wrap;

        .formGroup {
            width: 100%;

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}

.addedProductsListWrap {
    .addedProductsList {
        .addedProduct {
            padding: 16px 0;
            border-bottom: 1px solid #D4D4D4;

            &:first-child {
                border-top: 1px solid #D4D4D4;
            }

            .addedProductName {
                color: $primary;
            }

            .addedProductAction {
                cursor: pointer;

                .toggleArrow {
                    transition: all 0.2s ease-in-out;

                    svg {
                        transition: all 0.3s ease-in-out;
                        margin-left: 8px;
                    }

                    &.show {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }

                svg {
                    &:not(:first-child) {
                        margin-left: 12px;
                    }
                }
            }
        }
    }

    .addItemButton {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        position: absolute;
        bottom: 16px;
        right: 0;
    }
}

.formCheck {
    &:not(:last-child) {
        margin-right: 16px;
    }

    .formCheckInput {
        width: 1em;
        height: 1em;
        margin-top: 0.25em;
        vertical-align: top;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid rgba(0, 0, 0, .25);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        border-radius: 4px;

        // &::after {
        //     content: "";
        //     box-shadow: 0px 0px 0px 1px #ffffff;
        //     width: 84%;
        //     height: 86%;
        //     position: absolute;
        //     border-radius: 50%;
        //     top: 1px;
        //     left: 1px;
        // }

        &:checked {
            background-color: $primary;
            border-color: $primary;

            +.formCheckLabel {
                color: $primary;
            }

            &[type=checkbox] {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' strokeWidth='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
            }
        }

        &[type=radio] {
            border-radius: 50%;
        }
    }

    .formCheckLabel {
        cursor: pointer;
        margin-left: 8px;
        color: #999;
    }
}

.viewProductWrap {
    .viewProductHeader {
        margin-top: 20px;
        border-bottom: 4px solid #ededed;
        padding-bottom: 16px;

        .headerRow {
            small {
                text-align: right;
            }

            &Left,
            &Right {
                font-size: 16px;
                line-height: 20px;
                color: #000;
            }
        }

        .ProductUniqueTag {
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            color: #000;

            @media only screen and (max-width: 769px) {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .moreDetailsRow {
            margin-top: 14px;

            .moreDetails {
                font-size: 16px;
                line-height: 20px;
                color: #000;

                &:not(:first-child) {
                    border-left: 1px solid #d4d4d4;
                    padding-left: 8px;
                    margin-left: 8px;
                }
            }
        }

        .productSupplierName {
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
            color: #000;

            &:not(:first-child) {
                border-left: 1px solid #d4d4d4;
                padding-left: 8px;
                margin-left: 8px;
            }
        }

        .productSupplierAddress {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            // color: $primary;
            margin-top: 8px;
            cursor: pointer;
            flex-wrap: nowrap;

            @media only screen and (max-width: 769px) {
                font-size: 14px;
            }

            svg {
                stroke: $primary;
                width: 16px;
                margin-right: 8px;
                flex-shrink: 0;
            }
        }
    }

    .productDescriptionWrap {
        .productDescription {
            padding: 16px 0 12px;

            &:not(:first-child) {
                border-top: 2px solid #ededed;
            }

            .productTitle {
                font-size: 20px;
                font-weight: 600;
                color: #000;
            }

            .productQuantityList {
                margin-top: 8px;

                .productQuantity {
                    font-size: 14px;
                    font-weight: 400;
                    color: #121212;

                    &:not(:first-child) {
                        margin-left: 4px;
                        padding-left: 4px;
                        border-left: 1px solid #ededed;
                    }
                }
            }

            .table {
                width: 100%;

                &.productPrice {
                    margin-top: 8px;

                    thead>tr>td {
                        color: $primary;
                        padding: 4px 4px 4px 0;
                    }

                    tbody>tr>td {
                        padding: 4px 4px 4px 0;
                    }
                }
            }
        }

        .productExpiryDescription {
            border-top: 2px solid #ededed;
            border-bottom: 2px solid #ededed;
            padding-bottom: 12px;

            .productExpirtList {
                margin-top: 12px;

                .productQuantity {
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #121212;

                    &:not(:first-child) {
                        margin-left: 8px;
                        padding-left: 8px;
                        border-left: 1px solid #ededed;
                    }
                }
            }
        }
    }

    .disclaimerNotes {
        border-top: 0;
        border-bottom: 4px solid #ededed;
        padding-bottom: 6px;
        margin-top: 6px;

        &.borderTop {
            border-top: 4px solid #ededed;
            margin-top: 16px;
        }
    }

    &.exchangeReturn {
        .exchangeId {
            color: rgba(0, 0, 0, .2);
        }
    }
}

.consolidateStrip {
    .consolidateDataIcon {
        svg {
            width: 40px;
            height: 40px;
        }
    }
}

.barcode {
    height: calc(100vh - 220px);

    svg {
        width: 100%;
    }
}

.addProductBtnMobile {
    bottom: 20px;
    z-index: 3;
    right: 20px;
}