@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600&display=swap');
@import 'reset';
@import 'variables';
@import 'form';
@import 'button';
@import 'animation';

* {
    font-family: 'Poppins', sans-serif !important;
    box-sizing: border-box;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

// Flex Layout ------------------------------

.dFlex {
    display: flex;
    flex-wrap: wrap;

    &.jc-flex-end {
        justify-content: flex-end;
    }

    &.jcSpaceBetween {
        justify-content: space-between;
    }

    &.rowReverse {
        flex-direction: row-reverse;
    }

    &.jc-space-around {
        justify-content: space-around;
    }

    &.jc-space-even {
        justify-content: space-evenly;
    }

    &.jcContentCenter {
        justify-content: center;
    }

    &.flexColumn {
        flex-direction: column;
    }

    &.flexColumnReverse {
        flex-direction: column-reverse;
    }

    &.fdColumn {
        flex-direction: column;
    }

    &.flexNowrap {
        flex-wrap: nowrap;
    }

    &.d-col {
        flex-direction: column;
    }

    &.d-row {
        flex-direction: row;
    }

    &.aiCenter {
        align-items: center;
    }

    &.ai-flex-end {
        align-items: flex-end;
    }

    &.ai-flex-start {
        align-items: flex-start;
    }

    &.fgrow1,
    .fgrow1 {
        flex-grow: 1;
    }

    &.flex1,
    .flex1 {
        flex: 1;
    }

    .jc-content-center {
        justify-content: center;
    }

    .flexBreak {
        flex: 0 0 100% !important
    }

    .flexBreak50 {
        flex: 0 0 calc(50% - 8px) !important
    }

    .flexBreakAuto {
        flex: 1 1 auto !important
    }
}

.itemCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 340px);
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.strong {
    font-weight: 600;
}

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left !important;
}

.pointer {
    cursor: pointer;
}

textarea {
    resize: none;
}

*::-webkit-scrollbar {
    display: none;
}

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

// Spacer ------------------------------

.p-2 {
    padding: 8px;
}

.p-3 {
    padding: 12px;
}

.p-4 {
    padding: 16px;
}

.pl-4 {
    padding-left: 16px;
}

.pl-8 {
    padding-left: 32px;
}

.m-0 {
    margin: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-1 {
    margin-left: 4px;
}

.ml-2 {
    margin-left: 8px;
}

.ml-3 {
    margin-left: 12px;
}

.ml-4 {
    margin-left: 16px;
}

.ml-5 {
    margin-left: 20px;
}

.mt-2 {
    margin-top: 8px;
}

.mt-5 {
    margin-top: 20px;
}

.mb-2 {
    margin-bottom: 8px;
}

.mb-4 {
    margin-bottom: 20px;
}

.mb-5 {
    margin-bottom: 24px;
}

.mr-2 {
    margin-right: 8px;
}

.textBold {
    font-weight: 600;
}

.primaryText {
    color: $primary;
}

.primaryImportant {
    color: $primary !important;
}

.textGreen {
    color: #00a650 !important;
}

.textRed {
    color: #ff0000 !important;
}

.width100 {
    width: 100%;
}

.cursorPointer {
    cursor: pointer;
}

.webVisible {
    @media only screen and (max-width: 769px) {
        display: none;
    }
}

.mwebVisible {
    @media only screen and (min-width: 769px) {
        display: none;
    }
}

.addProductBtnMobile {
    .btn {
        @media only screen and (max-width: 769px) {
            line-height: 0;

            &.lhauto {
                line-height: unset;
            }
        }

        -webkit-animation-name: zoomIn;
        animation-name: zoomIn;

        svg>g>path {
            fill: white;
        }
    }
}

@for $i from 1 through 3 {
    button {
        &:nth-child(#{$i}) {
            animation-delay: #{$i * 0.2}s;
        }
    }
}