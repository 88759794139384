@import '../../../common/scss/common.scss';

.acknowledgeScreen {
    height: 100%;

    @media only screen and (max-width: 769px) {
        position: fixed;
        z-index: 50;
        background-color: rgba(245, 245, 245);
        width: 100%;
        overflow: hidden;
    }

    .acknowledgeScreenMain {
        color: #121212;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        margin-top: 24px;
    }

    .acknowledgeScreenSub {
        color: #6E6E6E;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin-top: 8px;
    }

    .acknowledgeScreenFooter {
        margin-top: 16px;

        .btn {
            flex-grow: 1;
            justify-content: center;

            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }
}