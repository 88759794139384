@import '../../common/scss/common.scss';

.createAccount {
    .otpInfo,
    .createAccountWrap {
        margin-top: 32px;
    }
}

.formSection {
    &:not(:first-child) {
        margin-top: 24px;
    }

    .formHeading {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: $primary;
        margin-bottom: 8px;
    }

    .positionedIcon {
        position: absolute;
        right: 12px;
        top: 16px;
        cursor: pointer;
        transition: 0.1s all ease-in;

        &:hover {
            filter: opacity(0.5);
        }
    }
}

.locationContainer {
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 40px 120px;
    overflow: auto;

    .welcomeHeading {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .welcomeSubHeading {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 16px;
    }
}

.otpInput {
    &.formGroup {
        .formControl {
            text-align: center;
            max-width: 60px;
            border: 0;
            border-radius: 0;
            border-bottom: 2px solid #ccc;
            outline: none;
        }
    }
}
